const custom =  (title, text, color) => {
  return {
    title: title,
    text: text,
    color: color
  }
}


export default custom;
