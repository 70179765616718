import Connection from "../Connection.js";
const logger = async (data) => {
    let log = await Connection.post("log", {
        id_colaborador: data.id_colaborador,
        funcao: data.funcao,
        setor: data.setor,
        ip: data.ip,
        texto:  data.texto
    });
    return log
  }
export default logger;
