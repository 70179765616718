import { splice } from "core-js/fn/array";

const removerMascara = {
  remove: async function (obj) {
    const defaultDelimiters = /[-!$%^&*()_+|~=`{}[\]:";'<>?,./\\ ]/;
    return await obj.replace(new RegExp(defaultDelimiters, "g"), "");
  },
  cnpj: async function (obj) {
    if (obj.includes('REPE')) {
      obj = await obj.split('REPE')[0]
    }
    const defaultDelimiters = /[-!$%^&*()_+|~=`{}[\]:";'<>?,./\\ ]/;
    obj = await obj.replace(new RegExp(defaultDelimiters, "g"), "");
    return obj
  }
};
export default removerMascara;
