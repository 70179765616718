import Connection from "../Connection.js";
let permissoes = JSON.parse(localStorage.getItem("atividades"));
let atividades = {},
  result = {};
if (permissoes) {
  atividades = Connection.get("atividade").then(async token => {
    return token;
  });
  result = {
    permissoes: permissoes,
    atividades: atividades
  };
}

export default result;
