
/**
 * Consulta de Cnpj
 * By: cezin
 */

const receita = {
  receitaWs: async function(cnpj) {
    const defaultDelimiters = /[-!$%^&*()_+|~=`{}[\]:";'<>?,./\\ ]/;
    cnpj = cnpj.replace(
      new RegExp(defaultDelimiters, "g"),
      ""
    );
    let url = "https://www.receitaws.com.br/v1/cnpj/" + cnpj;
    const proxyurl = "https://cors-anywhere.herokuapp.com/";
    let res = await fetch(proxyurl + url)
      .then(async response => await response.json())
      .catch( async () => null)
    return res
  }
};

export default receita

