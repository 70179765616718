import Success from "./success.js";
import Error from "./error.js";
import AuthError from "./authError";
import RecoverPassword from "./recoverPassword.js"
import Empty from "./empty.js"
import Custom from "./custom.js";

export default {
    Success,
    Error,
    AuthError,
    RecoverPassword,
    Empty,
    Custom
}
