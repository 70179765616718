import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    routes: [{
            // =============================================================================
            // MAIN LAYOUT ROUTES
            // =============================================================================
            path: "pages",
            component: () =>
                import ("./layouts/main/Main.vue"),
            children: [
                // =============================================================================
                // Theme Routes
                // =============================================================================
                {
                    path: "/home",
                    name: "Home",
                    component: () =>
                        import ("./views/Home.vue")
                },
                {
                    path: "/kanban",
                    name: "lista de prospecções",
                    component: () =>
                        import ("./views/pages/kanban/kanban.vue")
                },
                {
                    path: "/kanban-renovacao",
                    name: "lista de renovacoes",
                    component: () =>
                        import ("./views/pages/kanbanRenovacao/kanbanRenovacao.vue")
                },
                {
                    path: "/contrato",
                    name: "Contrato",
                    component: () =>
                        import ("./views/pages/contrato/contrato.vue")
                },
                {
                    path: "/perfil",
                    name: "perfil",
                    component: () =>
                        import ("./views/pages/perfil/perfil.vue")
                },
                {
                    path: "/financeiro",
                    name: "financeiro",
                    component: () =>
                        import ("./views/pages/financeiro/financeiro.vue")
                },
                {
                    path: "/DRE",
                    name: "DRE",
                    component: () =>
                        import ("./views/pages/financeiro/relatorios/DRE.vue")
                },
                {
                    path: "/planta",
                    name: "planta",
                    component: () =>
                        import ("./views/pages/planta/Planta.vue")
                },
                {
                    path: "/caixa",
                    name: "caixa",
                    component: () =>
                        import (
                            "./views/pages/financeiro/components/debito_credito/debito_credito.vue"
                        )
                },
                {
                    path: "/contas-receber",
                    name: "contas-receber",
                    component: () =>
                        import (
                            "./views/pages/financeiro/components/contas_receber/contas_receber.vue"
                        )
                },
                {
                    path: "/notas-entrada",
                    name: "notas-entrada",
                    component: () =>
                        import (
                            "./views/pages/financeiro/components/contas_pagar/contas_pagar.vue"
                        )
                },
                {
                    path: "/cobranca",
                    name: "cobranca",
                    component: () =>
                        import ("./views/pages/financeiro/components/cobranca/cobranca.vue")
                },
                {
                    path: "/retorno",
                    name: "retorno",
                    component: () =>
                        import ("./views/pages/financeiro/components/retorno/retorno.vue")
                },
                {
                    path: "/nota",
                    name: "notas",
                    component: () =>
                        import ("./views/pages/financeiro/components/nota/nota.vue")
                },
                {
                    path: "/boleto",
                    name: "boleto",
                    component: () =>
                        import ("./views/pages/financeiro/components/boleto/boleto.vue")
                },
                {
                    path: "/notas-fornecedor",
                    name: "notas-fornecedor",
                    component: () =>
                        import (
                            "./views/pages/financeiro/components/notas_fornecedor/notas_fornecedor.vue"
                        )
                },
                {
                    path: "/contratos-faturamento",
                    name: "contratos-faturamento",
                    component: () =>
                        import (
                            "./views/pages/financeiro/components/contratos_faturamento/contratos_faturamento.vue"
                        )
                },
                {
                    path: "/colaborador",
                    name: "colaborador",
                    component: () =>
                        import ("./views/pages/colaborador/colaborador.vue")
                },
                {
                    path: "/assunto",
                    name: "assunto",
                    component: () =>
                        import ("./views/pages/assunto/assunto.vue")
                },
                {
                    path: "/cliente",
                    name: "cliente",
                    component: () =>
                        import ("./views/pages/cliente/cliente.vue")
                },
                {
                    path: "/proveniente",
                    name: "proveniente",
                    component: () =>
                        import ("./views/pages/proveniente/proveniente.vue")
                },
                {
                    path: "/documentos",
                    name: "documentos",
                    component: () =>
                        import ("./views/pages/documentos/documentos.vue")
                },
                // {
                //   path: "/clienteProsp",
                //   name: "ClientePropseccao",
                //   component: () => import("./views/pages/cliente/components/clienteProspeccao.vue")
                // },
                {
                    path: "/rota",
                    name: "rota",

                    component: () =>
                        import ("./views/pages/rota/rota.vue")
                },
                {
                    path: "/prospeccao",
                    name: "prospeccao",
                    component: () =>
                        import ("./views/pages/prospeccao/prospeccao.vue")
                },
                {
                    path: "/pragas",
                    name: "pragas",
                    component: () =>
                        import ("./views/pages/praga/praga.vue")
                },
                {
                    path: "/produtos",
                    name: "produtos",
                    component: () =>
                        import ("./views/pages/produto/produto.vue")
                },
                {
                    path: "/metodologia",
                    name: "metodologia",
                    component: () =>
                        import ("./views/pages/metodologia/metodologia.vue")
                },
                {
                    path: "/metodo",
                    name: "metodo",
                    component: () =>
                        import ("./views/pages/metodo/metodo.vue")
                },
                {
                    path: "/segmento",
                    name: "segmento",
                    component: () =>
                        import ("./views/pages/segmento/segmento.vue")
                },
                {
                    path: "/armazenamento",
                    name: "Armazenamento",
                    component: () =>
                        import ("./views/pages/armazenamento/armazenamento.vue")
                },
                {
                    path: "/area",
                    name: "area",
                    component: () =>
                        import ("./views/pages/area/area.vue")
                },
                {
                    path: "/fornecedor",
                    name: "fornecedor",
                    component: () =>
                        import ("./views/pages/fornecedor/fornecedor.vue")
                },
                {
                    path: "/portador",
                    name: "portador",
                    component: () =>
                        import ("./views/pages/portador/portador.vue")
                },
                {
                    path: "/controle",
                    name: "controle",
                    component: () =>
                        import ("./views/pages/controle/controle.vue")
                },
                {
                    path: "/cidade",
                    name: "cidade",
                    component: () =>
                        import ("./views/pages/cidade/cidade.vue")
                },
                {
                    path: "/frase",
                    name: "frase",
                    component: () =>
                        import ("./views/pages/frase/frase.vue")
                },
                {
                    path: "/tipo_faturamento",
                    name: "tipo_faturamento",
                    component: () =>
                        import ("./views/pages/clausula/clausula.vue")
                },
                {
                    path: "/log",
                    name: "log",
                    component: () =>
                        import ("./views/pages/log/log.vue")
                },
                {
                    path: "/endereco",
                    name: "endereco",
                    component: () =>
                        import ("./views/pages/endereco/endereco.vue")
                },
                {
                    path: "/datas-especiais",
                    name: "Datas especiais",
                    component: () =>
                        import ("./views/pages/dataEspecial/dataEspecial.vue")
                },
                {
                    path: "/parametro",
                    name: "parametro",
                    component: () =>
                        import ("./views/pages/parametro/parametro.vue")
                },
                {
                    path: "/centro-custo",
                    name: "Centro de custos",
                    component: () =>
                        import ("./views/pages/centroCusto/centroCusto.vue")
                },
                {
                    path: "/plano-conta",
                    name: "Plano de contas",
                    component: () =>
                        import ("./views/pages/planoConta/planoConta.vue")
                },
                {
                    path: "/relatorio-vendas",
                    name: "Relatorio de Vendas",
                    component: () =>
                        import ("./views/pages/relatorios/vendas/relatorio_vendas.vue")
                },
                {
                    path: "/relatorio-pco",
                    name: "relatorio-pco",
                    component: () =>
                        import ("./views/pages/relatorios/pco/relatorio_pco.vue")
                },
                {
                    path: "/empresa",
                    name: "empresa",
                    component: () =>
                        import ("./views/pages/empresa/empresa.vue")
                },
                {
                    path: "/meta",
                    name: "meta",
                    component: () =>
                        import ("./views/pages/Metas.vue")
                },
                {
                    path: "/lev-associar",
                    name: "Associar levantamento",
                    component: () =>
                        import ("./views/pages/rotas/rotasAssoc.vue")
                },
                {
                    path: "/rota-config",
                    name: "rotaConfig",
                    component: () =>
                        import ("./views/pages/rotas/rota.vue")
                },
                {
                    path: "/tecnico-turno",
                    name: "rotaConfig",
                    component: () =>
                        import ("./views/pages/rotas/tecnicoTurno.vue")
                },
                {
                    path: "/lev-confirmacao",
                    name: "confirmacao",
                    component: () =>
                        import ("./views/pages/confirmacao/levConfirmacao.vue")
                },
                {
                    path: "/os-confirmacao",
                    name: "Os Confirmacao",
                    component: () =>
                        import ("./views/pages/confirmacao/osConfirmacao.vue")
                },
                {
                    path: "/avaliacao-tecnica",
                    name: "Avaliação",
                    component: () =>
                        import ("./views/pages/os/avaliacao.vue")
                },
                {
                    path: "/treinamento",
                    name: "Treinamento",
                    component: () =>
                        import ("./views/pages/institucional/treinamento.vue")
                },
                {
                    path: "/os-associar",
                    name: "Os Associar",
                    component: () =>
                        import ("./views/pages/os/osAssoc.vue")
                },
                {
                    path: "/agenda-os",
                    name: "Agenda OS",
                    component: () =>
                        import ("./views/pages/agenda-servicos/os/agendaOS.vue")
                },
                {
                    path: "/agenda-lembrete",
                    name: "Agenda Lembrete",
                    component: () =>
                        import ("./views/pages/lembrete/lembrete.vue")
                },
                {
                    path: "/retorno-os",
                    name: "Retorno OS",
                    component: () =>
                        import ("./views/pages/retornoOS/retornoOS")
                },
                {
                    path: "/agenda-lev",
                    name: "Agenda Lev",
                    component: () =>
                        import ("./views/pages/agenda-servicos/lev/agendaLev.vue")
                },
                {
                    path: "/pos-venda",
                    name: "Pos-Venda",
                    component: () =>
                        import ("./views/pages/os/posVenda.vue")
                },
                {
                    path: "/movimento",
                    name: "movimento",
                    component: () =>
                        import ("./views/pages/estoque/movimento/movimento.vue")
                },
                {
                    path: "/relatorio",
                    name: "relatorio",
                    component: () =>
                        import ("./views/pages/estoque/relatorio/relatorio.vue")
                },
                {
                    path: "/relatorio-local",
                    name: "relatorio-local",
                    component: () =>
                        import ("./views/pages/estoque/relatorio_local/relatorio_local.vue")
                },
                {
                    path: "/backlog",
                    name: "Backlog",
                    component: () =>
                        import ("./views/pages/backlog/backlog.vue")
                },
                {
                    path: "/formas-pagamentos",
                    name: "FormasPagamentos",
                    component: () =>
                        import ("./views/pages/formasPagamentos/formasPagamentos.vue")
                },
                {
                    path: "/metas",
                    name: "Metas",
                    component: () =>
                        import ("./views/pages/metas/metas.vue")
                },

                {
                    path: "/emails",
                    name: "Emails",
                    component: () =>
                        import ("./views/pages/emails/emails.vue")
                },
                {
                    path: "/logout",
                    name: "Logout",
                    component: () =>
                        import ("./views/Logout.vue")
                },
                {
                    path: "/tipos-dispositivo",
                    name: "Tipos de Dipositivo",
                    component: () =>
                        import ("./views/pages/device-types/deviceTypes.vue")
                },
                {
                    path: "/kanban-reports",
                    name: "Kanban para reportes",
                    component: () =>
                        import ("./views/pages/reports/kanban.vue")
                },
            ]
        },
        // =============================================================================
        // FULL PAGE LAYOUTS
        // =============================================================================
        {
            path: "",
            component: () =>
                import ("@/layouts/full-page/FullPage.vue"),
            children: [
                // =============================================================================
                // PAGES
                // =============================================================================
                {
                    path: "/",
                    name: "page-login",
                    component: () =>
                        import ("@/views/pages/auth/Login.vue")
                },
                {
                    path: "/pages/inicio",
                    name: "Nova empresa",
                    component: () =>
                        import ("@/views/pages/inicial/inicial.vue")
                },
                {
                    path: "/avaliacao",
                    name: "Avaliações Técnicas",
                    component: () =>
                        import ("@/views/pages/os/avaliacoesTecnicos.vue")
                },
                {
                    path: "/relatorio-dispositivo",
                    name: "Relatório do disposivo",
                    component: () =>
                        import (
                            "./views/pages/contrato/components/os/manage-devices/device-report/report.vue"
                        )
                },
                {
                    path: "/pages/error-404",
                    name: "page-error-404",
                    component: () =>
                        import ("@/views/pages/Error404.vue")
                },
                {
                    path: "/primeiro-acesso/*",
                    name: "primeiro-acesso",
                    component: () =>
                        import ("./views/pages/auth/PrimeiroAcesso.vue")
                },
                {
                    path: "/recuperar-acesso/*",
                    name: "recuperar-acesso",
                    component: () =>
                        import ("./views/pages/auth/EsqueciMinhaSenha.vue")
                }
            ]
        },
        // Redirect to 404 page, if no match found
        {
            path: "*",
            redirect: "/pages/error-404"
        }
    ]
});

router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById("loading-bg");
    if (appLoading) {
        appLoading.style.display = "none";
    }
});

export default router;