/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue"
import App from "./App.vue"
import BootstrapVue from "bootstrap-vue"
import ElementUI from "element-ui"

// Vuesax Component Framework
import VTooltip from 'v-tooltip'
import Vuesax from "vuesax"
import "material-icons/iconfont/material-icons.css" //Material Icons
import "vuesax/dist/vuesax.css" // Vuesax
import VeeValidate from "vee-validate"
import "bootstrap/dist/css/bootstrap.css"
import "bootstrap-vue/dist/bootstrap-vue.css"
import VueSkeletonLoading from "vue-skeleton-loading"
import ToggleButton from "vue-js-toggle-button" // Vuesax
import "element-ui/lib/theme-chalk/index.css"
import locale from 'element-ui/lib/locale/lang/pt-br'
import Vuetify from 'vuetify'
import "./assets/css/vuetify.min.css"
// import 'vuetify/dist/vuetify.min.css'
import VueVideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'

import Connection from "./functions/Connection.js"
import FormartData from "./functions/helpers/formatData.js"
import HttpErrors from "./functions/helpers/httpErrors.js"
import Atividades from "./functions/helpers/atividades.js"
import Parametros from "./functions/helpers/parametros.js"
import RemoverMascara from "./functions/helpers/removerMascara.js"
import Logger from "./functions/helpers/logger.js"
import Receita from "./functions/cnpj.js"
import Currency from "./functions/currency.js"
import CurrencyFormatterOptions from "./functions/currency-formatter-options.js"
import Notify from "./functions/notify/exports"
import Validators from "./functions/validators/messages.js"
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import VueMask from 'v-mask'
import VueExcelXlsx from "vue-excel-xlsx"
import VueCurrencyInput from 'vue-currency-input'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'


////
Vue.use(VueCurrencyInput)
Vue.use(PerfectScrollbar)
Vue.use(VueExcelXlsx)
Vue.use(require('vue-moment'))
Vue.use(ToggleButton)
Vue.use(VTooltip)
Vue.use(VeeValidate)
Vue.use(Vuesax)
Vue.use(BootstrapVue)
Vue.use(VueSkeletonLoading)
Vue.use(ElementUI, { locale })
Vue.use(VueMaterial)
Vue.use(VueMask)
Vue.use(Vuetify)
Vue.use(VueVideoPlayer)


Vue.prototype.$http = Connection
Vue.prototype.$formartData = FormartData
Vue.prototype.$httpErrors = HttpErrors
Vue.prototype.$atividades = Atividades
Vue.prototype.$parametros = Parametros
Vue.prototype.$removerMascara = RemoverMascara
Vue.prototype.$logger = Logger
Vue.prototype.$receita = Receita
Vue.prototype.$currencyFormatterOptions = CurrencyFormatterOptions
Vue.prototype.$currency = Currency
Vue.prototype.$notify = Notify
Vue.prototype.$validators = Validators

//import Axios from "axios";
import "../themeConfig.js"

// Globally Registered Components
import "./globalComponents.js"

// Styles: SCSS
import "./assets/scss/main.scss"

// Tailwind
import "@/assets/css/main.css"

// Vue Router
import router from "./router"

// Vuex Store
import store from "./store/store"

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from "vue2-hammer"
Vue.use(VueHammer)

// PrismJS
import "prismjs"
import "prismjs/themes/prism-tomorrow.css"

// Feather font icon
require("./assets/css/iconfont.css")

// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app")
