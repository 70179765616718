<!-- =========================================================================================
	File Name: App.vue
	Description: Main vue file - APP
	----------------------------------------------------------------------------------------
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Author: Pixinvent
	Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div id="app">
    <!--keep-alive>
      <router-view :key="$route.fullPath"></router-view>
    </keep-alive-->
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
import themeConfig from "@/../themeConfig.js"

export default {
  watch: {
    "$store.state.theme" (val) {
      this.toggleClassInBody(val)
    }
  },
  methods: {
    toggleClassInBody (className) {
      if (className == "dark") {
        if (document.body.className.match("theme-semi-dark"))
          document.body.classList.remove("theme-semi-dark")
        document.body.classList.add("theme-dark")
      } else if (className == "semi-dark") {
        if (document.body.className.match("theme-dark"))
          document.body.classList.remove("theme-dark")
        document.body.classList.add("theme-semi-dark")
      } else {
        if (document.body.className.match("theme-dark"))
          document.body.classList.remove("theme-dark")
        if (document.body.className.match("theme-semi-dark"))
          document.body.classList.remove("theme-semi-dark")
      }
    },
    handleWindowResize () {
      this.$store.commit("UPDATE_WINDOW_WIDTH", window.innerWidth)
    },
    handleScroll () {
      this.$store.commit("UPDATE_WINDOW_SCROLL_Y", window.scrollY)
    }
  },
  mounted () {
    this.toggleClassInBody(themeConfig.theme)
    this.$store.commit("UPDATE_WINDOW_WIDTH", window.innerWidth)
  },
  async created () {
    window.addEventListener("resize", this.handleWindowResize)
    window.addEventListener("scroll", this.handleScroll)
  },
  destroyed () {
    window.removeEventListener("resize", this.handleWindowResize)
    window.removeEventListener("scroll", this.handleScroll)
  }
};
</script>
<style>
html {
  overflow-y: hidden !important;
}
/* #remove-opacity.vs-input--input:disabled {
  opacity: 1 !important;
} */
.vx-card__body {
  padding: 7px !important;
}
.not-data-table {
  content: "TEST" !important;
}
.vs-table--not-data {
  content: "TEST" !important;
}

.con-colors ul {
  display: flex;
  align-items: center;
  justify-content: center;
}
.small-font {
  font-size: 12px;
}
.con-colors li {
  display: block;
  position: relative;
  background: rgb(155, 250, 149);
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(255, 255, 255);
  border-radius: 5px;
  cursor: default;
}
.danger-box {
  background: #c0564b !important;
  box-shadow: 0px 15px 40px -10px #c0564b;
}
.danger-boxlist {
  background: #000a12 !important;
  box-shadow: 0px 15px 30px -10px #c0564b;
}
.vs-popup--header h3 {
  background: #000a12 !important;
  color: white;
}
.vs-popup--close {
  background: #c0564b !important;
  color: white !important;
}
.btn.active {
  background-color: #021a33 !important;
}
.btn-warning.btn-lg.active {
  background-color: #695600 !important;
  color: white !important;
}
.vs-input--label {
  color: black !important;
}
.vs-select--label {
  color: black !important;
}
</style>
