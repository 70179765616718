const HttpErrors = (error) => {
  if (error.response == undefined) {
    return {
      error: true,
      code: error,
      response: error,
      fullError: error,
      text: 'Erro desconhecido',
      title: 'Ocorreu um erro',
      color: 'danger'
    }
  }
  let msg
  switch (error.response.status) {
    case 204:
      msg = "Nenhum conteúdo encontrado"
      break
    case 203:
      msg = "Você não tem permissão para acessar isso, faça o login para continuar"
      break
    case 401:
      msg = "Usuário ou senha incorretos."
      break
    case 403:
      msg = "Você não tem permissão para acessar isso, faça o login para continuar"
      break
    case 404:
      msg = "Essa página não foi encontrada"
      break
    case 413:
      msg = "O tamanho do arquivo excede o limite."
      break
    case 500:
      msg = "Ocorreu um erro com o servidor, tente novamente em instantes."
      break
    case 501:
      msg = "Ops... Isso não está implementado ainda"
      break
    case 503:
      msg = "Serviço temporariamente indisponível"
      break
    case 504:
      msg = "Todas as pesquisas pelo CEP retornaram vazias."
      break
    case 505:
      msg = "CPF e CNPJ devem ser únicos."
      break
    case 506:
      msg = "Email já cadastrado no sistema!"
      break
    case 123:
      msg = "Todas as pesquisas pelo CEP retornaram vazias."
      break
    case 409:
      msg = "Exclusão imposível: Conflitos de conexões entre registros"
      break
    case 513:
      msg = "Email não foi encontrado!"
      break

    default:
      msg = error.response.data
      break
  }
  return {
    error: true,
    code: error.response.status,
    response: error.response,
    fullError: error,
    text: msg,
    title: 'Ocorreu um erro',
    color: 'danger'
  }
}



export default HttpErrors
