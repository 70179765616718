const currency = (value) => {
  let toFormat = 0
  if (value) {
    toFormat = value
  }
  var formated = parseFloat(toFormat).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
  return formated
}

export default currency
