import axios from 'axios';
import HOSTS from "../enviroments.js";

const TOKEN = localStorage.getItem("token");
const IP = window.localStorage.getItem("ip")


let config = {
    baseURL: HOSTS.API
};

const Connection = axios.create(config);

const authInterceptor = config => {
    config.headers = {
        Authorization: "Bearer ".concat(TOKEN),
        ip: IP
    }
    return config;
};

const loggerInterceptor = config => {
    return config;
}

Connection.interceptors.request.use(authInterceptor);
Connection.interceptors.request.use(loggerInterceptor);

Connection.interceptors.response.use(
    response => {
        return response.data;
    },
    error => {
        return Promise.reject(error);
    }
);

export default Connection;
