export default {
    email: "Digite um endereço de email valido",
    password: "A senha deve conter mais que 6 caracteres",
    passwordLogin: "Digite sua senha",
    empty: "Este campo não pode ser vazio",
    select: "Selecione uma opção",
    data: "Selecione uma data",
    phone: "Digite um telefone valido (Somente numeros)",
    cnpj: "Digite um CNPJ valido (Somente numeros)",
    cpf: "Digite um CPF valido (Somente numeros)",
    cep: "Digite um CEP valido (Somente numeros)",
    rg: "Digite um RG valido (Somente numeros)",
    numeric: "Somente são permitidos numeros",
    photo: "Seleciona uma imagem"

}
