export default {
    send: {
        title: "Sucesso",
        text: "Você receberá um email com as instruções para recuperar sua senha.",
        color: "success"
    },
    finish: {
        title: "Sucesso",
        text: "Sua senha foi atualizada com sucesso, redirecionando para a tela de login em 4 seg.",
        color: "success"
    },
    errorToken: {
        title: "Ocorreu um erro",
        text: "Seu token expirou e não será possível alterar sua senha.",
        color: "danger"
    },
    errorMinLength: {
        title: "Ocorreu um erro",
        text: "A senha deve ter no minimo 6 digitos",
        color: "danger"
    },
    errorDiffPassword: {
        title: "Ocorreu um erro",
        text: "As senhas devem ser iguais",
        color: "danger"
    }
}