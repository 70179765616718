let dia, mes, ano

const formatData = {
  formatar: async function (data, opcao) {
    data = new Date(data)
    dia = data.getUTCDate()
    if (dia < 10) {
      dia = "0" + dia
    }
    mes = data.getUTCMonth() + 1
    if (mes < 10) {
      mes = "0" + mes
    }
    ano = data.getUTCFullYear()
    if (opcao == 1) {
      return ano + "-" + mes + "-" + dia
    } else {
      return dia + '/' + mes + '/' + ano
    }
  },
  somarDias: async function (data, valorSoma) {
    data = new Date(data)
    let resultado = data.getDate() + parseInt(valorSoma)
    data = new Date(data.setDate(resultado))
    return data
  },
  somarMeses: async function (data, valorSoma) {
    data = new Date(data)
    let resultado = data.getUTCMonth() + parseInt(valorSoma)
    data = new Date(data.setMonth(resultado))
    return data
  },
  dataFiltro: function () {
    let date = new Date()
    date = this.removeTimeZone(date)
    return date.split("T")[0]
  },
  dataHoje: function () {
    let date = new Date()
    return this.removeTimeZone(date)
  },
  removeTimeZone: function (data) {
    let userTimezoneOffset = new Date(data).getTimezoneOffset() * 60000
    return new Date(data.getTime() - userTimezoneOffset).toISOString()
  }
}

export default formatData
